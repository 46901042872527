import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Img from "gatsby-image"
import { graphql } from "gatsby"
import StyledHeading from "../components/StyledHeading"

const OverMoi = ({ data }) => (
  <Layout>
    <SEO
      title="Over moi"
      description="Ik ben Imgerd en ik schrijf. Soms over iets dat me opvalt in het
          openbaar vervoer, soms over een situatie op het fietspad."
    />
    <div className="container mx-auto mt-16 md:mt-20 md:grid md:grid-cols-2">
      <div className="px-6">
        <StyledHeading tag="h1" text="Over moi" size="text-3xl" />
        <p className="mt-12 leading-loose">
          Ik ben Imgerd en ik schrijf over dingen. Letterlijk. Soms gaat het
          over iets dat me opvalt terwijl ik door de stad fiets, soms over de
          arbeidsmarkt en soms over iets dat niets met de arbeidsmarkt of door
          de stad fietsen te maken heeft. Zo nu en dan met een beetje
          frustratie, maar altijd realistisch. Wat ik denk is wat ik schrijf. En
          de hete brij? Daar loop ik dwars doorheen.
        </p>
        <p className="mt-5 leading-loose">
          Ik vind muziek leuk, volg Ajax en Arsenal op de voet en profileer
          mezelf graag als een fulltime zonaanbidder die graag in de schaduw zit
          als het te warm is. Ik maak muziek onder het alias{" "}
          <a
            href="https://soundcloud.com/2nd-floor-grocery-store"
            className="text-gray-600 underline"
            target="_blank"
            rel="noreferrer noopener"
          >
            2nd Floor Grocery Store
          </a>
          , schrijf voor{" "}
          <a
            href="https://www.frankwatching.com/archive/author/imgerd-friso/"
            className="text-gray-600 underline"
            target="_blank"
            rel="noreferrer noopener"
          >
            Frankwatching
          </a>{" "}
          en werk als creatieveling en seriewoordenaar.
        </p>
        <p className="mt-5 leading-loose">
          Deze over-moi-pagina bestaat zodat jij me goed leert kennen, maar ik
          heb liever dat je me leert kennen door gewoon m’n blogs te lezen.
        </p>
      </div>
      <div className="px-6 mt-8 md:mt-24">
        <Img
          fluid={data.file.childImageSharp.fluid}
          className="mt-5 md:max-w-sm"
        />
      </div>
    </div>
  </Layout>
)

export const pageQuery = graphql`
  query OverMoi {
    file(relativePath: { eq: "media/IMG_4018-320x320.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default OverMoi
